<template>
  <Observer
    :root-margin="rootMargin"
    :threshold="threshold"
    @on-change="onDebounceChange"
  >
    <transition
      appear
    >
      <c-box
        height="100%"
        :opacity="withOpacity ? (isIntersecting ? 1 : 0) : 1"
      >
        <slot />
      </c-box>
    </transition>
  </Observer>
</template>

<script>
import Observer from 'vue-intersection-observer'
import { CBox } from '@chakra-ui/vue'
import _ from 'lodash'

export default {
  name: 'TransitionObserver',
  components: {
    Observer,
    CBox,
  },
  props: {
    rootMargin: {
      type: String,
      default: '0px',
    },
    threshold: {
      type: [Array, Number],
      default: 0.5,
    },
    withOpacity: {
      type: Boolean,
      default: true,
    },
    debounceDelay: {
      type: Number,
      default: 0,
    },
  },
  emits: ['on-intersect'],
  data() {
    return {
      isIntersecting: false,
    }
  },
  created() {
    this.onDebounceChange = _.debounce(this.onChange, this.debounceDelay)
  },
  methods: {
    onChange(entry, unobserve) {
      // After loading Cancel monitoring, optimise performance
      if (entry.isIntersecting) {
        this.$emit('on-intersect')
        unobserve()
      }
      this.isIntersecting = entry.isIntersecting
    },

  },
}
</script>
